<template>
    <b-modal
      ref="modal-save-sozlesme"
      ok-title="Kaydet"
      cancel-title="Kapat"
      size="xl"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      no-enforce-focus
      centered
      @hidden="hiddenModal"
      @ok="handleOk"
    >
       <editor 
            v-model="modalObject.item.text" 
            api-key="'qagffr3pkuv17a8on1afax661irst1hbr4e6tbv888sz91jc'"
            :init="{
              plugins: [
                'print preview paste importcss searchreplace autolink autosave save directionality code fullscreen image link media template table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap uploadimage',
              ],
               toolbar:
                'undo redo | bold italic underline strikethrough | \
                fontselect fontsizeselect formatselect | \
                alignleft aligncenter alignright alignjustify | outdent indent | \
                numlist bullist checklist | \
                forecolor backcolor casechange permanentpen formatpainter removeformat colorpicker | pagebreak | charmap | \
                fullscreen  preview  print | insertfile image media pageembed link anchor | \
                a11ycheck ltr rtl | showcomments addcomment | uploadimage',
              language: 'tr_TR',
              min_height: 600,
              valid_elements: '*[*]',
              extended_valid_elements: 'style,link[href|rel]',
              custom_elements: 'style,link,~link',
              force_br_newlines: true,
              forced_root_block: false,
            }" />
    </b-modal>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';

export default {
    components: {
        Editor,
    },
    props: {
        modalObject: {
        type: Object,
         default() {
            return {}
          },
        },
    },
    data() {
        return {
        }
    },
  methods: {
    saveSozlesme() {
        this.modalObject.onSuccess(this.modalObject.item.text, this.modalObject.item.type)
    },

    initialModal() {
      this.$refs['modal-save-sozlesme'].show();
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.saveSozlesme()
    },
    hiddenModal() {
      this.$refs['modal-save-sozlesme'].hide()
      this.modalObject.showModal = false;
    },
  },
  mounted() {
    this.initialModal()
  },
}
</script>

<style>

</style>
