<template>
  <b-row>
    {{sozlesmeler.KvkkSaklamaImhaPolitikasi}}
    <b-col cols="12" md="12" lg="12">
      <b-form-group :label="$t('Dil Seçiniz')" label-for="sDilKodu">
        <v-select
          v-model="selectedLanguage"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="dilOptions"
          :reduce="(val) => val.value"
          @input="fetchSozlesmes"
          input-id="selectedLanguage"
        />
        </b-form-group>
    </b-col>
    <b-col cols="6" md="6" lg="6">
      <b-card>
        <b-card-title class="mb-3">
          Satış Sözleşmesi 
          <b-button @click="openModal(sozlesmeler.satisSozlesmesi,'satisSozlesmesi')" 
            variant="primary" class="float-right">
            <feather-icon
              icon="EditIcon"
              class="mr-50" />
             {{ $t('Güncelle') }}
          </b-button>
        </b-card-title>
        <!-- <b-card-text v-html="sozlesmeler.satisSozlesmesi" /> -->
      </b-card>
    </b-col>
    <b-col cols="6" md="6" lg="6">
      <b-card>
        <b-card-title class="mb-3">
          KVKK Sözleşmesi 
          <b-button @click="openModal(sozlesmeler.kvkSozlesmesi,'kvkSozlesmesi')" 
            variant="primary" class="float-right">
            <feather-icon
              icon="EditIcon"
              class="mr-50" />
             {{ $t('Güncelle') }}
          </b-button>
        </b-card-title>
        <!-- <b-card-text v-html="sozlesmeler.kvkSozlesmesi" /> -->
      </b-card>
    </b-col>
    <b-col cols="6" md="6" lg="6">
      <b-card>
        <b-card-title class="mb-3">
          Kvk Politikasi
          <b-button @click="openModal(sozlesmeler.kvkkPolitikasi,'kvkkPolitikasi')" 
            variant="primary" class="float-right">
            <feather-icon
              icon="EditIcon"
              class="mr-50" />
             {{ $t('Güncelle') }}
          </b-button>
        </b-card-title>
        <!-- <b-card-text v-html="sozlesmeler.kvkkPolitikasi" /> -->
      </b-card>
    </b-col>
    <b-col cols="6" md="6" lg="6">
      <b-card>
        <b-card-title class="mb-3">
          Kvk Saklama Imha Politikasi
          <b-button @click="openModal(sozlesmeler.kvkkSaklamaImhaPolitikasi,'kvkkSaklamaImhaPolitikasi')" 
            variant="primary" class="float-right">
            <feather-icon
              icon="EditIcon"
              class="mr-50" />
             {{ $t('Güncelle') }}
          </b-button>
        </b-card-title>
        <!-- <b-card-text v-html="sozlesmeler.kvkkSaklamaImhaPolitikasi" /> -->
      </b-card>
    </b-col>
    <b-col cols="6" md="6" lg="6">
      <b-card>
        <b-card-title class="mb-3">
          Kvkk Veri sorumlu başvuru metni
          <b-button @click="openModal(sozlesmeler.kvkkVeriSorumluBasvuruMetni,'kvkkVeriSorumluBasvuruMetni')" 
            variant="primary" class="float-right">
            <feather-icon
              icon="EditIcon"
              class="mr-50" />
             {{ $t('Güncelle') }}
          </b-button>
        </b-card-title>
        <!-- <b-card-text v-html="sozlesmeler.kvkkVeriSorumluBasvuruMetni" /> -->
      </b-card>
    </b-col>
     <sozlesme-modal v-if="modalObject.showModal" :modalObject="modalObject" />
  </b-row>
</template>

<script>
import { mapGetters } from 'vuex';
import StaticOptions from '@/common/options/StaticOptions';
import AlertService from '@/common/alert.service'
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'
import SozlesmeModal from './SozlesmeModal.vue';

export default {
  components: {
      SozlesmeModal,
  },
  data() {
      return {
        dilOptions: StaticOptions.dilOptions,
        selectedLanguage: 'tr',
        sozlesmeler: {},
        modalObject: {
          item: { },
          showModal: false,
          onSuccess: this.save,
        },
      };
  },
  methods: {
    fetchSozlesmes() {
      this.$store.dispatch('sozlesmeYonetim/fetchSozlesmes', { sDilKodu: this.selectedLanguage }).then(result => {
        this.sozlesmeler = this.$store.state.sozlesmeYonetim.sozlesmeler;
      });
    },
    openModal(sozlesme, type) {
      this.modalObject.item = { text: sozlesme, type };
      this.modalObject.showModal = true;
    },
    save(text, type) {
        const sozlesme = { ...this.sozlesmeler }
        if (type === 'kvkSozlesmesi') sozlesme.kvkSozlesmesi = text;
        if (type === 'satisSozlesmesi') sozlesme.satisSozlesmesi = text;
        if (type === 'kvkkPolitikasi') sozlesme.kvkkPolitikasi = text;
        if (type === 'kvkkSaklamaImhaPolitikasi') sozlesme.kvkkSaklamaImhaPolitikasi = text;
        if (type === 'kvkkVeriSorumluBasvuruMetni') sozlesme.kvkkVeriSorumluBasvuruMetni = text;
        sozlesme.sDilKodu = this.selectedLanguage;

        this.$store.dispatch('sozlesmeYonetim/saveSozlesme', sozlesme, this)
        .then(response => {
        if (response.statusCode === 200) {
            this.$toast({
            component: ToastificationSuccess,
            position: 'top-right',
            props: { text: response.message },
            })
            this.modalObject.showModal = false;
            this.fetchSozlesmes();
        } else {
            AlertService.error(this, response.message)
        }
        });
    },
  },
  created() {
    this.fetchSozlesmes();
  },
};
</script>

<style>
</style>
